@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
    /* margin-top: auto; */
    display: flex;
    justify-content: center;
    position: relative;
    /* top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -4rem; */
}

html,
body {
    font-family: "Montserrat", sans-serif;
}

h1 {
    font-weight: 800;
    letter-spacing: 1px;
}

.center-vh {
    display: flex;
    align-items: center;
    justify-content: center;
}

.App {
    /* display: flex;
    flex-direction: column; */
    height: 100vh;
}

