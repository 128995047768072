.center {
    /* margin-top: auto; */
    display: flex;
    justify-content: center;
    position: relative;
    /* top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -4rem; */
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap");

html,
body {
    font-family: "Montserrat", sans-serif;
}

h1 {
    font-weight: 800;
    letter-spacing: 1px;
}

.center-vh {
    display: flex;
    align-items: center;
    justify-content: center;
}

.App {
    /* display: flex;
    flex-direction: column; */
    height: 100vh;
}
